.bushire{
    background-image:linear-gradient(248.08deg, rgba(51, 51, 51,0.8), rgba(51, 51, 51, 0.8)),
             url("../../assets/img/bushire.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 100px 0;
    color: white;
}

.bushire h1{
    max-width: 530px;
    font-weight: bold;
    font-size: 46px;
    line-height: 56px;
    font-family: "EuclidCircularB-Regular";
}

.bushire p{
   max-width: 601px;
   font-size: 14px;
   line-height: 26px;
}

.hirebus-btn{
    width: 20%;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #F2F2F2;
}

.vector-hirebus{
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 6px 0px;
    border-radius: 4px;
    background: #56CCF2;
}

.how-it-works{
    padding: 40px 0px;
}

.how-it-works h1{
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    font-family: "EuclidCircularB-Regular";
}

.how-it-works h5{
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.18px;
    line-height: 32px;
    font-family: "EuclidCircularB-Regular";
}

.how-it-works p{
    max-width: 376px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}
.availablebus{
    padding: 50px 0px;
    background: rgba(86, 204, 242, 0.2);
}

.availablebus h1{
    max-width: 379px;
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    font-family: "EuclidCircularB-Regular";
}

.availablebus p{
    max-width: 459px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
}

.availablebus h3{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.availablebus ul{
    list-style-image: url('../../assets/img/ellipse-hirebus.png');
}
.availablebus li{
padding: 7px 0px 0px 10px ;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.5px;
}

.other-things-text{
    max-width: 463px !important;
    letter-spacing: 0.5px;
    padding-left: 35px;
}

.other-things-text ul{
    list-style-image: url('../../assets/img/ellipse-hirebus.png');
}
.pr-3{
    margin: -20px 0px 0px 0px;
}

@media only screen and (max-width: 1024px){
    .hirebus-btn{
        width: 100%;
    }
}