.passenger-details {
  background-color: #f5f5f5;
}

.trip-summary {
  background: rgb(255, 255, 255);
  /* background: rgba(86, 204, 242, 0.1); */
  box-shadow: 0px -3px 40px 6px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 20px;
}

.swal2-popup {
  max-width: 22em !important;
}

.trip-route {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 36px;
  color: #333333;
  margin-bottom: 10px;
}

.left-par {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: rgba(51, 51, 51, 0.8);
  margin-bottom: 10px;
}

.left-par span {
  color: #333333;
  font-weight: 500;
}

.stepform-card {
  background: white !important;
  /* background: #56cbf219!important; */
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 40px;
}

:where(.css-dev-only-do-not-override-1r287do).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background: rgba(242, 27, 27, 0.05) !important;
  border-color: rgba(242, 27, 27, 0.05) !important;
}

:where(.css-dev-only-do-not-override-1r287do).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background: rgba(242, 27, 27, 0.05) !important;
  border-color: rgba(242, 27, 27, 0.05) !important;
  color: red !important;
}

.anticon svg {
  color: white !important;
}
.stepform-card-sub {
  max-width: 550px;
  margin: 0 auto;
}

.digicard {
  background: linear-gradient(
    223.03deg,
    rgba(86, 204, 242, 0.5) 4.53%,
    rgba(16, 158, 204, 0.5) 81.59%
  );
  border-radius: 8px;
  width: 60px;
  height: 50px;
  margin: auto;
  padding-left: 10px;
}

.step-h1 {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 32px;
  /* color: #000000; */
  margin-bottom: 10px;
}

.step-p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(51, 51, 51, 0.8);
  margin-bottom: 10px;
}

.step-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(51, 51, 51, 0.8);
  margin-bottom: 10px;
}

.step-label span {
  font-weight: bold;
}

.timeline {
  position: relative;
}

/*Line*/
.timeline > li::before {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #e7e7e7;
  top: 0;
  bottom: 0;
  left: -28px;
}

/*Circle*/
.timeline > li::after {
  text-align: center;
  padding-top: 5px;
  z-index: 10;
  content: counter(item);
  position: absolute;
  width: 30px;
  height: 30px;
  color: #56ccf2;
  background-color: rgba(86, 204, 242, 0.3);
  border-radius: 50%;
  top: 0;
  left: -43px;
}

/*Content*/
.timeline > li {
  counter-increment: item;
  padding: 0px 0px 10px 0;
  margin-left: 0px;
  min-height: 70px;
  position: relative;
  list-style: none;
}
.timeline > li:nth-last-child(1)::before {
  width: 0px;
}

@media only screen and (max-wdith: 1024px) {
  .timeline > li::after {
    content: "";
    left: 0px;
  }
}

.passenger-amt {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
}

.p-amt {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #1f1f1f;

  border: 1px solid #d0c9d6;
  padding: 10px 20px;
  max-width: 200px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.p-nb {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(51, 51, 51, 0.6);
  max-width: 278px;
  margin: auto;
  margin-bottom: 15px;
}
@media only screen and (max-width: 1024px) {
  .p-nb {
    max-width: 100%;
  }
}

.pay-mthod {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #4f4f4f;
}

.payment-hire {
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: red; */
  width: 180px;
}
.new-payment-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: auto;
}
.pay-bg,
.pay-bg-wallet {
  /* background-color: rgba(255, 0, 0, 0.3); */
  /* width: 100%; */
  margin: 0 auto;
  /* padding: 10px; */
  /* border-radius: 5px; */
  margin-top: 20px;
  /* margin-right: 20px; */
  margin-bottom: 15px;
  cursor: pointer;
  /* border: 1px solid #ccc; */
  object-fit: cover;
  overflow: hidden;
  height: 50px;
  display: flex;
  align-items: center;
  flex-basis: 25%;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.151);
}

.pay-bg-hire {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  object-fit: cover;
  overflow: hidden;
  height: 50px;
  display: flex;
  align-items: center;
  flex-basis: 45%;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.274);
}

.pay-bg img {
  height: 100%;
  width: 50%;
  border-radius: 5px;
  padding: 5px 10px;
}
.pay-bg-wallet img {
  height: 100%;
  width: 30%;
  border-radius: 5px;
  /* padding: 10px; */
}

.pay-bg-hire img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}
.payment-border {
  border: 3px solid #ccc;
  border-radius: 5px;
  /* background: red; */
  overflow: hidden;
}

.wallet-image {
  width: 35% !important;
}

/* #order-3 {
  order:1 !important
} */
@media only screen and (max-width: 1024px) {
  .payment {
    flex-direction: column;
  }
  .pay-bg,
  .pay-bg-wallet {
    height: 40px;
    width: 50%;
  }

  /* .pay-bg img {
    height: 100%;
    width: 50%;
  } */
  .wallet-image {
    width: 30% !important;
  }

  .pay-bg-wallet img {
    height: 100%;
    width: 30%;
  }
}
.pay-bg img {
  /* height: 100%; */
  width: 100%;
}

.con1 {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.con1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkSpan {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: lightgrey;
  border-radius: 10%;
}

/* When the radio button is checked, add a blue background */
.con1 input:checked ~ .checkmarkSpan {
  background-color: rgba(242, 27, 27, 0.5);
  /* background-color: #17cbf2; */
}

.multiple-input {
  border: 1px solid rgba(242, 27, 27, 0.05);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  color: #333333;
  background: transparent;
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.05);
  border-radius: 8px;
}
.accordion-title-test {
  position: relative;
  border-bottom: 1px solid #ef3a37;
}

.actve-state {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #ef3a37;
}

.pickup-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.pickup-image img {
  width: 100%;
  height: 100%;
}

.payee {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.pick-up-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  /* width: 600px; */
  margin: 0 auto;
}
