.suggest-link:hover {
  color: black !important;
}

.suggest-image {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.suggest-image-app {
  margin-left: 15px;
  width: 50%;
  overflow: hidden;
}
.suggest-image-app img {
  margin-left: 50px;
}
.app-image1 {
  width: 467px;
}
.app-image2 {
  width: 467px;
  transform: translateX(-430px);
}

.suggest-text {
  margin-bottom: 40px;
  margin-left: 100px;
}

@media screen and (max-width: 668px) {
  .suggest-section {
    padding: 10px 0;
  }

  .suggest-image-app {
    margin-left: 40px;
    width: 100%;
    height: 280px;
  }

  .suggest-image-app img {
    margin-left: 0;
    /* width: 100%; */
  }
  .app-image2 {
    width: 467px;
    transform: translateX(-250px);
    /* background: red; */
  }

  .suggest-text {
    margin: 0;
  }
  /* .suggest-image {
    flex-direction: column;
  } */
}

.suggest-section {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background: rgba(86, 204, 242, 0.2);
  /* background-image: url("../../assets/img/Group\ 16696.svg"); */
  padding: 50px 0;
  position: relative;
}

.suggest-section h1 {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 12px;
}

.suggest-section p {
  max-width: 363px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 20px;
}

.suggest-section span {
  font-family: "EuclidCircularB-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #56ccf2;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 15px;
}

.suggest-section .download-btn-btn {
  width: 100px;
}
