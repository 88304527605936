.bookingStatusDetails {
  background: rgba(86, 204, 242, 0.1);
  margin: 20px 0 50px 0;
  border-radius: 20px;
  padding: 20px;
}

.check-in-ticket {
  width: 100%;
  position: relative;
}

.check-in-ticket::after {
  content: "";
  position: fixed;
  top: 30%;
  left: 17%;
  background-image: url("../../assets/img/Layer x0020 1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 50%;
  width: 65%;
  opacity: 0.1;
}
.check-in-ticket p {
  font-size: 0.9rem;
}

.ticket-qr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background: white;
  padding: 20px;
  overflow: hidden;
  z-index: 1;
}

.book-det {
  display: flex;
  flex-wrap: wrap;
}

.info-det {
  flex-basis: 20%;
  margin: 10px 0;
}
@media only screen and (max-width: 550px) {
  .info-det {
    flex-basis: 100%;
  }
  .check-in-ticket::after {
    width: 80%;
    left: 11%;
  }
}
.traveller-header {
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 40px;
  color: rgba(51, 51, 51, 0.8);
}
.det-label {
  color: #333333cc;
  font-size: 0.9rem;
  /* line-height: 24px; */
  /* color: rgba(51, 51, 51, 0.8); */
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
