.airtime-card {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    padding: 20px;
    flex-basis: 55%;
    border-radius: 8px;
    margin: 0 auto;
    /* margin: 20px; */
    box-shadow: 0 0 25px #33333315;

}

.back-arrow {
    position: relative;
}
.back {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.topup-details {
    padding-top: 10px;
}
.topup-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.topup-detail-label {
    font-weight: 400;
}
.topup-detail-value {
    font-weight: 600;
}