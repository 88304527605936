.paybills{
    padding: 80px 0px;
}
.paybills h1{
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
}

.paybills p{
    max-width: 544px;
    font-size: 14px;
    line-height: 20px;
}
.paybills-imgholder{
    padding: 0px 0px 0px 140px;
}
.bills-list {
    display: flex;
    align-items: center;
}
.bullet {
    position: relative;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: red;
    margin-right: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
}

.bill-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 50px 0;
}

@media only screen and (max-width: 550px) {
    .pay-bills {
        padding-top: 100px;
    }

    .paybills h1{
        font-size: 46px;
    }
    .bill-cards {
        flex-direction: column;
    }
    .paybills-imgholder{
        padding: 0px 0px 0px 0px;
    }
}

.bill-cards p {
    max-width: 400px;
    text-align: center;
    opacity: .8;
}
.security, .rewards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.security {
    /* border-right: 1px solid rgba(0, 0, 0, 0.219); */
    padding: 10px 20px;
    flex: 1;
}

.rewards {
    padding: 10px 20px;
    flex: 1;
}
/* .slide-partner {
    position: relative;
    height: 250px;
    max-width: 100%;
    margin: 0 10px;
}

.slide-partner img {
    object-fit: cover;
    object-position: top;
    height: 100%;
    width: 100%;
    border-radius: 14px;
} */