.auth-section ul {
  margin-left: 10px;
  list-style: none;
}

.auth-section li {
  display: flex;
  align-items: center;
  padding: 10px 0 0px 5px;
  /* list-style-image: url("../../assets/img/bulleting 3.png"); */

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.auth-section li img {
  margin-right: 10px;
  filter: brightness(0) saturate(100%) invert(34%) sepia(81%) saturate(7369%)
    hue-rotate(351deg) brightness(90%) contrast(105%);
}
