.pickup {
  padding: 80px 0px;
}


@media only screen and (max-width: 1024px) {
  .pickup {
    width: 100%;
    overflow-x: hidden;
  }
}
.pickup ol {
  padding: 25px;
}
.pickup li {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 0px 20px 0px;
}

#googleMaps {
  width: 500px;
  height: 500px;
}

.wrapper {
  cursor: help;
  position: relative;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.wrapper h2 {
  font-family: "EuclidCircularB-Bold";
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
  margin: 50px 0px 20px 0px;
}

.wrapper h2 span {
  font-size: 15px;
}

.wrapper .tooltip {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  background: #56ccf2;
  /* bottom: 10%; */
  color: #fff;
  display: block;
  left: -20px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 50px;
  border-radius: 20px;
  pointer-events: none;
  position: absolute;
  width: 500px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #1496bb 10px;
  top: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

.pickup-location{
  border-bottom: 1px solid rgba(86, 204, 242, 0.1);
  padding: 30px 0px;
}
@media only screen and (max-width: 1024px) {
  .wrapper .tooltip {
    width: 100%;
    left: 0px;
  }

}

@media screen and (max-width: 800px) {
  #googleMaps {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 500px) { 
  #googleMaps {
    /* width: 320px; */
    /* height: 400px; */
    display: none;
  }
}