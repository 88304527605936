.sidebar-sm{
    border-radius: 10px;
    background:rgba(86, 204, 242, 0.05);
    display: none;
  }
  
  .sidebar-sm ul{
    list-style-type: none;
    display:flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sidebar-nav{
    padding: 10px 10px 10px 10px;
    color: #333333;  
    font-weight:400;  
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
  
  .sidebar-nav:hover {
    background: rgba(86, 204, 242, 0.1);
  }
  
  .active .sidebar-nav {
  background: rgba(230, 230, 233, 0.1);
  border-bottom: 3px solid #E21D00;
  }

  @media only screen and (max-width:1024px) {
    .sidebar-sm{
      margin: 20px 0px 40px 0px;
      padding: 0px;
      display: block;
    }
}