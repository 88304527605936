@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

@font-face {
  font-family: "EuclidCircularB-Regular";
  src: url("../fonts/EuclidCircularB-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/EuclidCircularB-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/EuclidCircularB-Regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/EuclidCircularB-Regular.ttf")
      format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "EuclidCircularB-Bold";
  src: url("../fonts/EuclidCircularB-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/EuclidCircularB-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/EuclidCircularB-Bold.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/EuclidCircularB-Bold.ttf")
      format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "EuclidCircularB-Medium";
  src: url("../fonts/EuclidCircularB-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/EuclidCircularB-Medium.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/EuclidCircularB-Medium.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/EuclidCircularB-Medium.ttf")
      format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  margin: 0 !important;
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  background-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1340px !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #d0c9d6;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d0c9d6;
}

::placeholder {
  color: #d0c9d6;
}

a {
  text-decoration: none;
  color: black;
  /* color: #56ccf2; */
}

@media only screen and (max-width: 1024px) {
  ol {
    padding: 0;
  }
}

.socialfootericon .footer-img img {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.socialfootericon .footer-img {
  display: flex;
  justify-content: center;
}

a:hover img {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

a:hover {
  text-decoration: none;
  color: #56ccf2;
}

a i {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

a:hover i {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.row.row-grid [class*="col-"] + [class*="col-"] {
  margin-top: 15px;
}

@media (min-width: 1200px) {
  .row.row-grid [class*="col-lg-"] + [class*="col-lg-"] {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .row.row-grid [class*="col-md-"] + [class*="col-md-"] {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .row.row-grid [class*="col-sm-"] + [class*="col-sm-"] {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100vh;
  }
}

.mobileShow {
  display: none;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileShow {
    display: inline;
  }
}

.mobileHide {
  display: inline;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileHide {
    /* display: none; */
  }
}

.bg-indigo {
  background-image: url("../../assets/img/signup.jpg");
  background-color: rgba(86, 204, 242, 0.8);
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0 10px 10px 0;
}

.this-position {
  padding: 50px 70px;
}

@media only screen and (max-width: 1024px) {
  .this-position {
    padding: 50px 30px;
    padding-bottom: 25px;
  }

  .bus-center {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}

.this-position img {
  width: 70px;
}

.bg-indigo h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
  max-width: 400px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.bg-indigo p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  max-width: 500px;
}

@media only screen and (max-width: 1024px) {
  .bg-indigo p {
    display: none;
  }
  .bg-indigo h1 {
    /* margin-top: 30px; */
    /* margin-bottom: 0; */
    display: none;
  }
}

.forgot-password {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  margin-top: 10px;
  cursor: pointer;
}

.auth-section {
  background: #ffffff;
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 40px;
  width: 640px;
  max-width: 100%;
}

@media only screen and (max-width: 1024px) {
  .auth-section {
    padding-top: 20px;
  }
}

.all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-section h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #333333;
  margin-bottom: 5px;
  text-align: center;
}

.auth-section h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top: 15px;
  text-align: center;
}

.btn-social {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #252525;
  padding: 10px 25px;
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.08));
  outline: none;
  border: none;
}

.breaking-or {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #828282;
}

.breaking-or::before,
.breaking-or::after {
  display: inline-block;
  content: "";
  border-top: 1px dashed rgba(189, 189, 189, 0.8);
  width: 60%;
  margin: 0 15px;
  transform: translateY(-0.2rem);
}

@media only screen and (max-width: 1024px) {
  .breaking-or::before,
  .breaking-or::after {
    width: 60%;
  }
}

.auth-section .label-auth {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.auth-section button {
  margin-top: 20px;
}

.pass-wrapper {
  position: relative;
}

.passwordVisible {
  position: absolute;
  top: 15px;
  right: 15px;
}

.img-size {
  width: 17px;
}

.PhoneInputInput {
  border: 1px solid rgba(86, 204, 242, 0.5);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  color: #333333;
  background: rgba(86, 204, 242, 0.05);
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}

.PhoneInputCountry {
  height: 45px;
  background: rgba(86, 204, 242, 0.05);
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(86, 204, 242, 0.5);
  padding: 0 5px;
  border-radius: 8px;
}

.PhoneInputCountryIcon {
  width: 2.3571428571rem;
  height: 1.5rem;
  border-radius: 0.2857142857rem;
  box-shadow: none;
}

.PhoneInputCountryIconImg {
  border-radius: 0.2857142857rem;
}

/* The container */
.container-check {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: #333333;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 40px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid #e0e0e0;
  background-color: white;
}
@media only screen and (max-width: 550px) {
  .checkmark {
    left: -8px;
  }
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.general-par {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
}

.success-password {
  padding: 15px 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  background: #56ccf2;
  border-radius: 8px;
}

.success-password a {
  color: white;
}

.tick-img {
  width: 150px;
}

.gigm-red {
  color: #e21d00;
}

.subscribe-section {
  background-color: #f5f5f5;
  padding: 70px 0;
}

.bg-sub {
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(86, 204, 242, 0.1);
  border-radius: 10px;
  padding: 30px;
}

.bg-sub h1 {
  max-width: 450px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  color: #333333;
}

.bg-sub-button {
  position: relative;
  text-align: right;
  display: flex;
  align-items: center;
}

.home-button {
  position: absolute;
  right: 20px;
  /* top: 2px; */
}

.last-section {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px -25px;
  background-image: url("../../assets/img/bg vector.png");
  background-color: #f5f5f5;
  position: relative;
}

.last-section img {
  width: 50px;
}

.last-section h1 {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 20px;
  text-align: center;
}

.last-section p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 15px;
  transition: 0.3s ease;
  text-align: center;
}

.last-section p:hover {
  text-decoration: underline;
}

.last-section hr {
  border: 1px dashed #56ccf2;
  height: 0px;

  opacity: 0.6;
}

.h1-route {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  margin-top: 40px;
}

.p-route {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-top: 20px;
}

.bus-card {
  background: white;
  /* background: rgba(86, 204, 242, 0.1); */
  box-shadow: 0px -3px 40px 6px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  margin-bottom: 15px;
}

.img-shield {
  background: white;
  /* background: rgba(86, 204, 242, 0.06); */
  border-radius: 20px;
  object-fit: cover;
}
.img-shield img {
  width: 100%;
}
.select-h1 {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 36px;
  color: #333333;
  margin-bottom: 10px;
}

.select-p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 8px;
}

.select-p span {
  font-weight: normal;
  color: rgba(51, 51, 51, 0.8);
}

.select-p img {
  width: 13px;
}

.select-amt {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 10px;
}

.suc-err-page {
  padding: 150px 0px 0px 0px;
  background-color: #f5f5f5;
}

.route-card-h1 {
  font-family: "EuclidCircularB-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: #333333;
  margin-bottom: 15px;
}

.ticket-card {
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
}

.text-area {
  border: 1px solid rgba(242, 27, 27, 0.05);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
  padding: 10px 20px;
  color: #333333;
  background: rgb(255, 255, 255);
  /* background: rgba(86, 204, 242, 0.05); */
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.05);
  border-radius: 8px;
  outline: rgba(242, 27, 27, 0.05);
}

.route-card {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 10px;
}

.route-card-blue {
  background: rgba(86, 204, 242, 0.2);
  border-radius: 20px 20px 0px 0px;
  padding: 15px;
}

.route-card-red {
  background: rgba(226, 29, 0, 0.1);
  border-radius: 20px 20px 0px 0px;
  padding: 15px;
}

.route-card-white {
  padding: 15px;

  background: radial-gradient(
        circle at 0 100%,
        #ffffff 14px,
        rgba(255, 255, 255, 0.6) 15px
      )
      bottom left,
    radial-gradient(circle at 100% 100%, #ffffff 14px, rgb(224 244 253) 15px)
      bottom right;
  background-repeat: no-repeat;
}

.route-card-white-b {
  padding: 15px;

  background: radial-gradient(
        circle at 100% 0,
        #ffffff 14px,
        rgb(223 243 252) 15px
      )
      top right,
    radial-gradient(circle at 0 0, #ffffff 14px, rgb(224 244 253) 15px) top left;
  background-repeat: no-repeat;
}

.route-card-white-r {
  padding: 15px;
  background: radial-gradient(
        circle at 0 100%,
        #ffffff 14px,
        rgb(255 255 255 / 94%) 15px
      )
      bottom left,
    radial-gradient(
        circle at 100% 100%,
        #ffffff 14px,
        rgba(227, 30, 0, 0.6) 15px
      )
      bottom right;
  background-repeat: no-repeat;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #e21d00;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

.lds-default p {
  position: absolute;
  top: 30px;
  left: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.download-btn {
  background: #56ccf2;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  border: 0;
  margin-top: 20px;
}

.sticky {
  height: auto !important;
}

.con-sticky {
  position: sticky;
  height: 400px;
  top: 0px;
}

@media only screen and (max-width: 1024px) {
  .con-sticky {
    position: relative;
    height: 450px;
    top: 0px;
  }

  .bg-sub h1 {
    font-size: 24px;
  }

  /* .bg-sub-button {
    text-align: center;
  } */
}

.dashboardArea {
  display: flex;
  gap: 40px;
  padding: 130px 0px 0px 0px;
}
