.busterminal{
    padding: 50px 0px;
    position: relative;
    /* height: 100vh; */
    /* overflow-y: hidden; */
}

.terminals {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

.terminal-scroll {
    position: relative;
    height: 100%;
    overflow-y: scroll;
}

.busterminal h1{
    font-family: "EuclidCircularB-Bold";
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 20px;
    margin-top: 80px;
}

.google-sticky {
    position: absolute;
    top: 0;
    right: 7%;
}

.terminal-btn{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: "EuclidCircularB-Bold";
}

.terminal-img-holder{
    padding: 70px 0px;
}

.terminal-img-info{
    border-bottom: 1px solid rgba(86, 204, 242, 0.3);
    padding: 30px 0px;
}

.terminal-img-info h4{
    font-family: "EuclidCircularB-Bold";
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.terminal-img-info p{
    font-size: 13px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.7);
}

.terminalline{
    width: 30px;
    height: 0px;
    border-bottom: 1px solid rgba(86, 204, 242, 0.3);
    margin: 15px 0;
}

.terminal-img-info h6{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: "EuclidCircularB-Bold";
    color: rgba(51, 51, 51, 0.8);
}

@media screen and (max-width: 1100px) {
    .terminal-section{
        flex-direction: column;
    }

    .terminal-map, .terminal-details{
        max-width: 100%!important;
    }

    .terminal-details {
        margin-top: 20px!important;
    }
  }