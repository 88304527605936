.suggest-link:hover {
  color: black !important;
}

#suggest-form .suggest {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  min-height: 200px;
  max-width: 540px;
  backdrop-filter: blur(10px);
  margin-bottom: 40px;
  padding: 20px 40px;
}

/* .suggest-image {
  width: 100%;
}

.suggest-image-app img {
    margin-left: 30px;
} */

@media screen and (max-width: 668px) {
  #suggest-form .suggest {
    padding: 10px 15px;
  }

  /* .suggest-image-app img {
        margin-left: 0
    } */
}

#suggest-form .suggest label {
  color: white;
  font-size: 0.7rem;
  opacity: 0.7;
  padding-left: 10px;
}

/* 
  .suggest-section {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background: rgba(86, 204, 242, 0.2);
    padding: 50px 0;
    position: relative;
  } */

/* .suggest-section h1 {
    font-family: "EuclidCircularB-Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  
  .suggest-section p {
    max-width: 363px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .suggest-section span {
    font-family: "EuclidCircularB-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #56ccf2;
    background: #ffffff;
    border-radius: 8px;
    padding: 8px 15px;
  }
  
  .suggest-section .download-btn-btn {
    width: 100px;
  } */

.css-yk16xz-control {
  background: transparent !important;
  font-size: 0.9rem;
  padding: 4px 10px;
  border-radius: 8px !important;
  /* border: 1px solid rgba(86, 204, 242, 0.5) !important; */
}
