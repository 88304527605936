
.profilePic h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  padding: 0px 0px 10px 0px;
}

.contentSettings{
  padding: 20px;
}

.setting-row {
  margin: 20px 0px 0px 0px;
  padding: 20px 60px 50px 60px;
}

.setting-row label {
  padding: 0px 0px 0px 10px;
  font-size: 12px;
  line-height: 18px;
}

.changepin {
  width: 50%;
  margin: auto;
}

.changepin h2 {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.changepin label {
  padding: 0px 0px 0px 10px;
  font-size: 12px;
  line-height: 18px;
}

::placeholder {
  padding: 0px 0px 0px 10px;
  font-size: 15px;
  line-height: 20px;
  color: #d0c9d6;
}

.password-input {
  position: relative;
}

.password-input i {
  position: absolute;
  right: 30px;
  top: 13px;
}

.tabsSettings {
  margin-top: 20px;
  padding: 0 20px;
}
.tabsSettings .tab-input {
  display: none;
}
.tabsSettings .tab-label {
  display: inline-block;
  padding: 6px 0 6px 0;
  margin: 0 -2px;
  width: 25%;
  border-bottom: 1px solid #56CCF2;
  text-align: center;
  cursor: pointer;
}
/* .tabsSettings .tab-label:hover {
  cursor: pointer;
} */
.tabsSettings input:checked + .tab-label {
  border-bottom: 2px solid red;
}
.tabsSettings #tab1:checked ~  .contentSettings #tabContent1,
.tabsSettings #tab2:checked ~  .contentSettings #tabContent2,
.tabsSettings #tab3:checked ~  .contentSettings #tabContent3,
.tabsSettings #tab4:checked ~  .contentSettings #tabContent4 {
  display: block;
}

.tabsSettings .contentSettings > div {
  display:none;
  padding-top: 20px;
  text-align: left;
}

.tabsSettings .contentSettings #tabContent1,
.tabsSettings .contentSettings #tabContent2,
.tabsSettings .contentSettings #tabContent3,
.tabsSettings .contentSettings #tabContent4 {
  display: none;
  padding-top: 20px;
  /* text-align: left;
  height: 192px;
  overflow: auto; */
}

.setting-button {
  width: 100%;
}

.pininput {
  position: relative;
}

.pininput label {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
}

.pininputicon {
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
}

.pin-btn {
  width: 40%;
  margin: auto;
}
.otpimg{
  width: 80px;
height: 80px;
padding: 20px 0px 0px 0px;
margin: auto;
border-radius: 50%;
background: rgba(86, 204, 242, 0.16);
}

/* .otpimg img{
  width: 100%;
height: 100%;
} */
@media only screen and (max-width: 1050px) {
  .pin-btn {
    width: 100%;
  }
  .setting-button {
    width: 100%;
  }

  .tab-label {
    font-size: 7.5px;
  }
  .tabsSettings {
    margin-top: 20px;
    padding: 20px 0px;
  }
  .changepin h2 {
    margin: 0px 0px 20px 0px;
  }
  .setting-row {
    padding: 0px;
  }
  .changepin {
    width: 100%;
  }
  
}
