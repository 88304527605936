.career {
    background: #F5F5F5;
    padding: 50px 0;
  }
  
  .career-top-section {
    width: 100%;
    background: #d4eef8;
    padding: 150px 0 80px 0;
  }


  .career-head-section {
    width: 100%;
    margin: 80px 0;
  }
  .head-section-text {
    width: 50%;
    overflow: hidden;
  }

  .head-section-text h1 {
    font-family: "EuclidCircularB-Bold";
      font-size: 4rem;
      font-weight: 900;
  }

  .head-section-text p {
      max-width: 550px;
  }
  .head-section-image {
    width: 400px;
    height: 400px;
    margin-left: auto;
  }

  .jobs-section {
    width: 100%;
    min-height: 350px;
    padding: 30px 20px;
    background: #E3F2F9;
    border-radius: 15px;

  }

  
  
  @media only screen and (max-width: 1024px) {

    .career-top-section {
        padding: 100px 10px 80px 10px;

    }

    .head-section-text {
      width: 100%;
      order: 2;
    }

    .head-section-text h1 {
        margin-top: 15px;
        font-size: 2rem;
    }
    .head-section-image {
      order: 1;
      width: 100%;
        height: 80%;
      margin-bottom: 0 !important;
      margin-top: 15px;
    }
  }
  