.customercenter {
  padding: 50px 0px;
}

.contact {
  padding: 150px 0px 50px 0px;
}

.contact h1 {
  font-family: "EuclidCircularB-Regular";
  font-weight: 600;
  font-size: 60px;
  line-height: 90px;
}

.contact p {
  font-size: 18px;
  line-height: 27px;
  max-width: 619px;
  margin: auto;
}

.customercare {
  background-image: linear-gradient(
      248.08deg,
      rgba(51, 51, 51, 0.8),
      rgba(51, 51, 51, 0.8)
    ),
    url("../../assets/img/customercare.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 150px 30px;
  color: white;
  border-radius: 20px;
}

.customercare i {
  color: white;
}

.customercare p {
  max-width: 470px;
  font-family: "EuclidCircularB-Bold";
  font-size: 35px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: left;
}

.customercare-emailus {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  font-family: Inter !important;
}

textarea {
  resize: none;
  width: 100%;
  height: 70px;
  border: 1px solid rgba(86, 204, 242, 0.5);
  background: rgba(86, 204, 242, 0.05);
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding: 10px 20px;
  color: #333333;
}

.customerform {
  padding: 8px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  /* height: 520px; */
  max-width: 350px;
  margin: 25px 0px 0px -140px;
}

.customerform h1 {
  font-family: "EuclidCircularB-Bold";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
.customerform p {
  padding: 4px 0px 7px 0px;
  font-size: 15px;
  line-height: 18px;
}

@media only screen and (max-width: 1024px) {
  .contact {
    padding: 80px 0px 35px 0px;
  }
  .contact h1 {
    font-size: 50px;
  }

  .customercenter {
    padding: 20px 0px;
  }
  .customercare {
    padding: 50px 30px;
  }


  .customercare p {
    font-size: 30px;
  }
  .customerform {
    margin: 0px;
    margin-top: 10px;
  }
  .customerform h1 {
      margin-top: 10px;
  }
}
