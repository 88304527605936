.billsPayment h1{
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
}

.billsPayment {
    background: #ffffff;
}

.bill-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin: 50px 0; */
}

@media only screen and (max-width: 550px) {
    .pay-bills {
        padding-top: 100px;
    }

    .billsPayment h1{
        font-size: 46px;
    }
    .bill-cards {
        flex-direction: column;
    }

}


.bill-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    flex-basis: 35%;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 0 25px #33333315;
    cursor: pointer;
}

.bill-card:hover {
    box-shadow: 0 0 15px #3333332d;
    transform: scale(1.1);
}
.bill-card p {
    font-size: 1.1rem!important;
}

.airtime-form {
    margin-top: 15px
}

.recent-cards {
    display: flex;
    margin-top: 10px;
}

.recent-card, .provider-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px 10px 0;
    cursor: pointer;
}

.recent-text {
    font-size: .8rem;
    margin-top: 10px;
}

.airtime-card{
    max-width: 500px;
    margin: 0 auto;
    box-shadow: 0 0 20px #00000018;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 50px;
}

.provider-card {
    background: #EEFAFE;
    border-radius: 3px;
    padding: 5px;
}