/* .enterprise{
    border: solid;
} */

.enterprise-partner {
  padding: 190px 0px 50px 0px;
  background-image: url("../../assets/img/Enterprisebg.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 650px) {
  .enterprise-partner {
    padding-top: 100px;
  }
}

.enterprise-partner h1 {
  font-family: "EuclidCircularB-Bold";
  max-width: 450px;
  font-size: 48px;
  line-height: 62px;
  padding: 0px 0px 10px 0px;
}

.enterprise-partner h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.partner-checkbox {
  padding: 10px 0px 0px 0px;
}

.partner-checkbox label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0px 10px;
}

.partner-checkbox p {
  max-width: 305px;
  padding: 0px 0px 10px 20px;
  font-size: 14px;
  line-height: 20px;
}

.slider-holder {
  padding: 50px 0px 0px 40px;
  margin-top: 70px;
  width: 856px;
  height: 320px;
  background: rgba(238, 250, 254, 0.4);
  /* background: rgba(226, 29, 0, 0.1); */
  border-radius: 20px;
}

.slide-partner {
  position: relative;
  height: 330px;
  max-width: 100%;
  margin: 0 10px;
}

.slide-partner img {
  object-fit: cover;
  object-position: top;
  height: 100%;
  width: 100%;
  border-radius: 14px;
}

.enterprise .slick-prev,
.enterprise .slick-next {
  top: 110% !important;
  background-color: black !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.enterprise .slick-prev {
  left: 10px !important;
}

.enterprise .slick-next {
  right: 670px !important;
}

.enterprise .slick-prev:before,
.enterprise .slick-next:before {
  font-family: "poppins" !important;
  font-size: 12px;
  line-height: 1;
  opacity: 1 !important;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.enterprise .slick-prev:hover,
.enterprise .slick-prev:focus,
.enterprise .slick-next:hover,
.enterprise .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:before {
  content: "←";
}

.slick-next:before {
  content: "→";
}
@media screen and (max-width: 1024px) {
  .enterprise .slick-prev,
  .enterprise .slick-next {
    display: none !important;
  }

  .slick-prev:before {
    display: none;
  }
  .slider-holder {
    display: none;
    padding: 50px 0px 0px 0px;
    margin-top: 30px;
    width: 100%;
    height: 90%;
  }
}
