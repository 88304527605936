.footer-text ul {
  list-style: none;
  text-align: start;
}


.footer-text li {
  font-size: 14px;
  line-height: 17px;
  color: red;
}
.socialfootericon img {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
/* .socialfootericon img:hover{
   transform: translateY(20px);
} */

.footer-cards {
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
}

.footer-card {
  flex-basis: 20%;
}

.footer-logo {
  display: none;
}


@media only screen and (max-width: 1024px) {
  .footer-logo {
    display: block;
  }
  .footer-logo-mobile {
    display: none;
    flex-basis: 100%;
  }

  .footer-card {
    flex-basis: 50%;
  }
}



  
  a:hover img {
    -webkit-animation-name: hvr-icon-wobble-horizontal;
    animation-name: hvr-icon-wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  .footer-block-square{
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 65px;
    right: 50px;
  }
  
  @-webkit-keyframes hvr-icon-wobble-horizontal {
    16.65% {
      -webkit-transform: translateX(6px);
      transform: translateX(6px);
    }
  
    33.3% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
  
    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
  
    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }
  
    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }
  
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  
  @keyframes hvr-icon-wobble-horizontal {
    16.65% {
      -webkit-transform: translateX(6px);
      transform: translateX(6px);
    }
  
    33.3% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
  
    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
  
    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }
  
    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }
  
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }