.this-position h1 {
  font-family: "EuclidCircularB-Bold";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.right-elements h1 {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  max-width: 450px;
  margin: auto;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
  .right-elements h1 {
    margin-top: 30px;
  }
}

.signUp-handle {
  position: relative;
  text-align: center;
}

.centered {
  position: absolute;
  top: 60%;
  left: 08%;
  transform: translate(-0%, -50%);
}

@media only screen and (max-width: 650px) {
  .centered {
    left: 28%;
  }
}

.centered-one {
  position: absolute;
  top: 65%;
  left: 0%;
  transform: translate(-0%, -50%);
}

.right-elements h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 8px;
  margin-top: 10px;
}

.right-elements p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  max-width: 200px;
}

.guest-view {
  background: rgba(255, 255, 255, 0.8);
  border: 2px dashed #56ccf2;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 200px;
  margin: auto;
  height: 238px;
  position: relative;
  text-align: center;
}

.guest-view p {
  max-width: 180px;
}

.guest-view h2 {
  margin-left: 10px;
}
