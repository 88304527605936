.hero-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: linear-gradient(
      248.08deg,
      rgba(235, 163, 154, 0) 13.9%,
      rgba(47, 81, 92, 0) 100.87%
    ),
    url("../../assets/img/herobg.jpg");
  padding-top: 150px;
  padding-bottom: 80px;
}
.date-selector-new {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  color: white;
  background: transparent;
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.111);
  border-radius: 8px;
  text-align: left;
  border: none;
}

.date-selector-new:disabled {
  background: #696969 !important;
}
.slick-cloned {
  display: none !important;
}

.booking_view_page[_ngcontent-prj-c265] {
  padding-top: "260px" !important;
  padding-bottom: "260px" !important;
}
.autosetWidth[_ngcontent-byw-c200] {
  padding-bottom: "260px" !important;
}
.landing_page[_ngcontent-slx-c137] .landing_page_bg[_ngcontent-slx-c137] {
  padding-bottom: "200px" !important;
}

.booking_view_page[_ngcontent-prj-c265] {
  padding-top: "260px" !important;
  padding-bottom: "260px" !important;
}
.autosetWidth[_ngcontent-byw-c200] {
  padding-bottom: "260px" !important;
}
.landing_page[_ngcontent-slx-c137] .landing_page_bg[_ngcontent-slx-c137] {
  padding-bottom: "200px" !important;
}

.hero-bg h1 {
  font-family: "EuclidCircularB-Bold", "Inter", sans-serif;
  font-style: normal;
  font-size: 48px;
  line-height: 54px;
  color: #ffffff;
  max-width: 545px;
}

.ant-select-selector {
  border: 1px solid rgba(242, 27, 27, 0.05) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 20px !important;
  height: 45px !important;
  width: 100%;
  padding: 10px 20px !important;
  color: black !important;
  text-shadow: 0px 0px 8px white !important;
  background: transparent !important;
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.05);
  border-radius: 8px !important;
}

.ant-select-selection-placeholder,
.css-lubpi1-placeholder,
.ant-select-selection-search-input {
  color: black !important;
  text-shadow: 0px 0px 8px white !important;
}

/* .anticon svg {
  color: white!important
} */

.hero-bg p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  max-width: 427px;
  margin-bottom: 40px;
}

.hot-trips h1 {
  font-family: "EuclidCircularB-Bold", "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  color: #333333;
}
@media only screen and (max-width: 1024px) {
  .hot-trips h1 {
    margin: 40px 0;
  }
}

.hot-trips p {
  font-family: "EuclidCircularB-Regular", "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #333333;
  max-width: 354px;
}
@media only screen and (max-width: 1024px) {
  .hot-trips p {
    margin-bottom: 30px;
  }
}

/* .prices-container {
    padding-left: 160px;
} */

.hold-items {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 0px 0px 14px 14px;
  width: 100%;
  position: absolute;
  height: 130px;

  left: 0px;
  bottom: 0px;
}

.centered-book {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}

.centered-book h2 {
  font-family: "EuclidCircularB-Medium", "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
  color: #ffffff;
}

.slide-image {
  position: relative;
  height: 350px;
  max-width: 100%;
  margin: 20px 10px;
}

.slide-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  /* height: 100%; */
  width: 100%;
  border-radius: 14px;
}

@media only screen and (max-width: 1024px) {
  .slide-image {
    height: 400px;
  }
}

.slick-prev,
.slick-next {
  top: 80% !important;
  background-color: black !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.slick-prev {
  left: -430px !important;
}

.slick-next {
  right: 1070px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "poppins" !important;
  font-size: 12px;
  line-height: 1;
  opacity: 1 !important;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.app-section {
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../../assets/img/Group\ 16696.svg"); */
  /* padding: 150px 0; */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: 485px;
  /* padding: 0 10%; */
  /* background: #56ccf2; */
}

.app-section-text {
  width: 50%;
  height: 485px;
  padding-left: 100px;
  display: flex;
  align-items: center;
  background: #56ccf2;
}
.app-section-image {
  width: 50%;
  height: 485px;
}

.appline {
  position: absolute;
  right: -480px;
  top: -162px;
  z-index: 1;
}

/* .mobileShow{
  border: solid;
} */
/* .image-mobile {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../../assets/img/mock\ up.svg");
  height: 230px;
  display: none;
} */
.black {
  color: rgb(0, 0, 0) !important;
}

.hero-flex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.left-card {
  width: 60%;
}

.right-card {
  width: 40%;
}

@media screen and (max-width: 800px) {
  .left-card {
    width: 50%;
  }

  .right-card {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .hero-flex {
    flex-direction: column;
  }
  .left-card {
    width: 100%;
  }

  .right-card {
    width: 100%;
  }

  #rcc-confirm-button {
    width: 300px !important;
  }
}
@media screen and (max-width: 768px) {
  .app-section {
    padding: 20px 0 50px 0;
  }

  .app-section-text {
    width: 100%;
    order: 2;
    height: 300px;
    padding-left: 20px;
  }
  .app-section-image {
    width: 100%;
    order: 1;
    height: 270px;
  }
  .appline {
    display: none;
  }
  #open-positions .positions .position-filters-user label {
    font-weight: 700 !important;
    font-size: 14px !important;
  }
}

.app-section h1 {
  font-family: "EuclidCircularB-Bold", "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 12px;
}

.app-section p {
  max-width: 363px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 20px;
}

.app-section span {
  font-family: "EuclidCircularB-Regular";
  font-style: normal;
  /* font-weight: 500; */
  /* font-size: 16px; */
  line-height: 26px;
  color: #56ccf2;
  background: #ffffff;
  border-radius: 8px;
  /* padding: 8px 15px; */
}

.app-section .download-btn-btn {
  width: 100px;
}

/* .haram {
  position: absolute;
  height: 280px;
  max-width: 100%;
  bottom: 0;
  left: 40%;
}

.haram .phone-img {
  object-fit: cover;
  object-position: top;
  height: 100%;
  width: 180px;
} */

.wallet-section {
  background-color: #f5f5f5;
  position: relative;
  padding: 100px 0;
}

.wallet-section h1 {
  max-width: 448px;
  font-family: "EuclidCircularB-Bold", "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 62px;
  color: #333333;
}

.wallet-section p {
  max-width: 328px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1f1f1f;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .wallet-section {
    padding: 0;
  }
  .wallet-section p {
    margin-bottom: 30px;
    margin-top: 20px;
  }
}
.walletredline {
  position: absolute;
  left: 110px;
  top: 57px;
}

.ep-section {
  background: rgba(86, 204, 242, 0.2);
}

.ep-apply {
  /* background: #f5f5f578; */
  background: #56ccf2;
  box-shadow: 0px 4px 50px rgba(86, 204, 242, 0.2);
  border-radius: 14px;
  padding: 20px;
  margin-top: -45px;
  width: 350px;
}

.ep-apply h1 {
  font-family: "EuclidCircularB-Bold", "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #1f1f1f;
  max-width: 297px;
  margin: 50px 0;
  margin-top: 20px;
}

.ep-apply p {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* color: #333333; */
  opacity: 0.8;
  max-width: 280px;
  margin-bottom: 25px;
}

.ep-btn {
  width: 63%;
}

.ep-apply-image {
  /* margin-left: 20px; */
  transform: translateX(100px);
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .ep-section {
    margin: 35px 0;
  }

  .popular-route {
    display: none;
  }

  .ep-apply {
    margin-top: 0;
    width: 100%;
  }

  .ep-apply-image {
    margin-left: 0;
    width: 100%;
    transform: translateX(0);
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  border: 1px solid rgba(242, 27, 27, 0.05);
  /* border: 1px solid rgba(86, 204, 242, 0.5); */
  font-style: normal;
  /* font-weight: bold; */
  font-size: 12px;
  line-height: 20px;
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  /* color: white; */
  color: black;
  /* text-shadow: 0 0 5px rgba(242, 27, 27, 0.9); */
  background: transparent;
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.05);
  border-radius: 8px;
}

.input:-internal-autofill-selected {
  background: transparent !important;
}

.css-1r5aefc-control:hover,
.css-1r5aefc-control:active {
  border: 1px solid rgba(242, 27, 27, 0.05) !important;
  /* border: 1px solid black !important; */
  box-shadow: none !important;
}

.select__control--is-focused,
.select__control--menu-is-open {
  border: none !important;
  outline: none !important;
}

#open-positions input[type="radio"] {
  display: none;
}

#open-positions .position-group {
  display: none;
}

#open-positions .active-content {
  display: block;
  padding: 20px;
  margin: auto;
  color: white;
}

#open-positions .positions {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
  min-height: 200px;
  /* width: 480px; */
}

#open-positions .positions .position-filters,
#open-positions .positions .position-filters-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  max-width: 80em;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.082);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support  */

  border-radius: 20px 20px 0px 0px;
  padding: 20px;
}

#open-positions .positions .position-filters label,
#open-positions .positions .position-filters-user label {
  cursor: pointer;

  font-family: "EuclidCircularB-Bold", "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 0 5px;
}

#open-positions .positions .position-filters label {
  color: rgba(255, 255, 255, 0.4);
}
#open-positions .positions .position-filters-user label {
  color: #333333;
  opacity: 0.699999988079071;
}

.active-tabs {
  color: white !important;
  border-bottom: 3px solid red;
  /* border-bottom: 3px solid #56ccf2; */
}
.active-tabs-user {
  color: black !important;
  border-bottom: 3px solid red !important;
}

#open-positions
  input[type="radio"]:checked
  + .positions
  .position-filters
  label {
  color: white;
  border-bottom: 3px solid red;
  /* border-bottom: 3px solid #4880ff; */
}
#open-positions
  input[type="radio"]:checked
  + .positions
  .position-filters-user
  label {
  color: black;
  border-bottom: 3px solid red;
}

#open-positions .positions .position-filters label:active {
  color: white;
  border-bottom: 3px solid red;
  /* border-bottom: 3px solid #56ccf2; */
}
#open-positions .positions .position-filters-user label:active {
  color: black;
  border-bottom: 3px solid red;
}

#open-positions .positions .position-filters label:focus {
  color: white;
  border-bottom: 3px solid red;
  /* border-bottom: 3px solid #56ccf2; */
}
#open-positions .positions .position-filters-user label:focus {
  color: black;
  border-bottom: 3px solid red;
}

/* #open-positions .positions .position {
  margin: auto;
  color: white;
} */

.bookSeat #content1,
.bookSeat #content2,
.bookSeat #content1-user,
.bookSeat #content2-user {
  display: none;
}

.bookSeat #content1 .css-1wa3eu0-placeholder,
.bookSeat #content2 .css-1wa3eu0-placeholder,
.bookSeat #content3 .css-1wa3eu0-placeholder,
.bookSeat #content4 .css-1wa3eu0-placeholder {
  color: white !important;
  /* text-shadow: 0 0 10px black;
  font-weight: 500; */
}
/* .css-lubpi1-placeholder, */
.bookSeat #content1-user .css-1wa3eu0-placeholder,
.bookSeat #content2-user .css-1wa3eu0-placeholder,
.bookSeat #content3-user .css-1wa3eu0-placeholder,
.bookSeat #content4-user .css-1wa3eu0-placeholder {
  color: black !important;
  /* text-shadow: 0 0 10px black;
  font-weight: 500; */
}

.css-yk16xz-control:active {
  background: red !important;
}

#content1
  .select_default_sty
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content2
  .select_default_sty
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content3
  .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content4
  .select_default_sty
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue {
  color: white !important;
  /* text-shadow: 0 0 15px black; */
  /* font-weight: bold; */
  /* font-size: 1.3rem; */
}
#content1-user
  .select_default_sty
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content2-user
  .select_default_sty
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content3-user
  .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content4-user
  .select_default_sty
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue {
  color: black !important;
  /* text-shadow: 0 0 15px black; */
  /* font-weight: bold; */
  /* font-size: 1.3rem; */
}

#react-select-3-input,
#react-select-27-input,
#react-select-29-input,
#react-select-5-input {
  color: white !important;
}

#react-select-31-input,
#react-select-39-input,
#react-select-47-input,
#react-select-43-input,
#react-select-45-input,
#react-select-13-input,
#react-select-15-input,
.css-5sz5u5-singleValue {
  color: black !important;
  text-shadow: 0 0 5px white !important;
}

#content1 .css-26l3qy-menu,
#content1-user .css-26l3qy-menu {
  background: rgba(227, 30, 0, 0.6) !important;
}
#content3 .css-yk16xz-control,
#content3-user .css-yk16xz-control {
  cursor: pointer;
  border: 1px solid rgba(242, 27, 27, 0.05);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  height: 45px;
  width: 100%;
  color: #333333;
  background: rgba(86, 204, 242, 0.05);
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.05);
  border-radius: 8px;
}

.css-b8ldur-Input {
  color: white !important;
}

.css-1pahdxg-control {
  border: 1px solid rgba(242, 27, 27, 0.05) !important;
  background: transparent !important;
  box-shadow: 0 0 1px rgba(86, 204, 242, 0.5) !important;
}
.css-1pahdxg-control:hover {
  border: 1px solid rgba(242, 27, 27, 0.05) !important;
  box-shadow: 0 0 1px rgba(86, 204, 242, 0.5) !important;
  background: transparent !important;
}

#content3
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content3-user
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue {
  color: #333333;
  font-size: 12px;
}

.css-1uccc91-singleValue {
  /* color: #fff !important; */
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

#content3 > .css-1pahdxg-control,
#content3-user > .css-1pahdxg-control {
  min-height: 45px !important;
  color: black !important;
  border-color: rgba(86, 204, 242, 0.5) !important;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07) !important;
}

#content3-user > .css-lubpi1-placeholder {
  color: black !important;
}

#content3-user > .css-1pahdxg-control {
  border-color: rgba(242, 27, 27, 0.05) !important;
}

#content3 > .css-1pahdxg-control:hover {
  border-color: rgba(86, 204, 242, 0.5) !important;
  color: black !important;
}

#content3-user > .css-1pahdxg-control:hover {
  border-color: rgba(242, 27, 27, 0.05) !important;
  color: black !important;
}

#content4 .css-yk16xz-control,
#content4-user .css-yk16xz-control {
  cursor: pointer;
  border: 1px solid rgba(242, 27, 27, 0.05);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  height: 45px;
  width: 100%;
  color: #333333;
  background: rgba(86, 204, 242, 0.05);
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.05);
  border-radius: 8px;
}

#content4
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue,
#content4-user
  > .css-yk16xz-control
  > .css-g1d714-ValueContainer
  > .css-1uccc91-singleValue {
  color: #333333;
  font-size: 12px;
}

#content4 > .css-1pahdxg-control,
#content4-user > .css-1pahdxg-control {
  min-height: 45px !important;
  color: black !important;
  border-color: rgba(86, 204, 242, 0.5) !important;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07) !important;
}

#content4 > .css-1pahdxg-control:hover,
#content4-user > .css-1pahdxg-control:hover {
  border-color: rgba(86, 204, 242, 0.5) !important;
  color: black !important;
}

#oneway-book-a-set,
#oneway-book-a-set-user {
  display: none;
}

#roundtrip-book-a-set,
#roundtrip-book-a-set-user {
  display: none;
}

.roundtrip-book-a-set,
.oneway-book-a-set,
.roundtrip-book-a-set-user,
.oneway-book-a-set-user {
  display: inline-block;
  margin: 0 10px 10px 0px;
  text-align: center;
  background-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: white;
  /* color: #56ccf2; */
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid white;
  /* border: 1px solid #56ccf2; */
}

.roundtrip-book-a-set-user,
.oneway-book-a-set-user {
  border: 1px solid red;
  color: red;
}

.roundtrip-book-a-set:hover,
.oneway-book-a-set:hover,
.roundtrip-book-a-set-user:hover,
.oneway-book-a-set-user:hover {
  color: white;
  background: red;
  /* background: #56ccf2; */
  cursor: pointer;
}

.roundtrip-book-a-set-user:hover,
.oneway-book-a-set-user:hover {
  background: red;
}

#oneway-book-a-set:checked + .oneway-book-a-set,
#oneway-book-a-set-user:checked + .oneway-book-a-set-user {
  color: white;
  border: none;
  background: red;
  /* background: #56ccf2; */
}

#roundtrip-book-a-set:checked + .roundtrip-book-a-set,
#roundtrip-book-a-set-user:checked + .roundtrip-book-a-set-user {
  color: white;
  border: none;
  background: red;
  /* background: #56ccf2; */
}

#oneway-book-a-set-user:checked + .oneway-book-a-set-user,
#roundtrip-book-a-set-user:checked + .roundtrip-book-a-set-user {
  background: red;
}

#oneway-book-a-set:checked ~ #content1,
#roundtrip-book-a-set:checked ~ #content2,
#oneway-book-a-set-user:checked ~ #content1-user,
#roundtrip-book-a-set-user:checked ~ #content2-user {
  display: block;
}

#content3,
#content4,
#content3-user,
#content4-user {
  display: none;
}

#oneway-hire,
#oneway-hire-user {
  display: none;
}

#roundtrip-hire {
  display: none;
}

.roundtrip-hire,
.oneway-hire {
  display: inline-block;
  margin: 0 10px 10px 0px;
  text-align: center;
  background-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: white !important;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid red;
  /* border: 1px solid #56ccf2; */
}
.roundtrip-hire-user,
.oneway-hire-user {
  display: inline-block;
  margin: 0 10px 10px 0px;
  text-align: center;
  background-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: red !important;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid red;
}
.roundtrip-hire-user,
.oneway-hire-user {
}

.roundtrip-hire:hover,
.oneway-hire:hover {
  color: white;
  background: red;
  /* background: #56ccf2; */
  cursor: pointer;
}
.roundtrip-hire-user:hover,
.oneway-hire-user:hover {
  color: white !important;
  background: red;
  cursor: pointer;
}

#oneway-hire:checked + .oneway-hire {
  color: white;
  background: red;
  /* background: #56ccf2; */
}

#oneway-hire-user:checked + .oneway-hire-user {
  color: white !important;
  background: red;
}
#roundtrip-hire:checked + .roundtrip-hire {
  color: white;
  background: red;
  /* background: #56ccf2; */
}
#roundtrip-hire-user:checked + .roundtrip-hire-user {
  color: white !important;
  background: red;
}

#oneway-hire:checked ~ #content3,
#roundtrip-hire:checked ~ #content4,
#oneway-hire-user:checked ~ #content3-user,
#roundtrip-hire-user:checked ~ #content4-user {
  display: block;
}

/* .css-1uccc91-singleValue {
  color: white;
} */
/* .inputField_Wrap {
  color: white !important;
} */
@media only screen and (max-width: 1024px) {
  .hero-bg {
    padding-top: 100px;
  }
  .hero-bg h1 {
    font-size: 34px;
    line-height: 35px;
  }
  .hero-bg p {
    margin-bottom: 20px;
  }
  .slick-slide {
    margin: 0;
  }

  .slick-list .slick-track {
    width: 1320px !important;
  }

  .prices-container {
    padding-left: 0px;
  }

  .app-cont {
    padding-left: 0px;
  }

  #open-positions .positions {
    min-height: 200px;
    width: 100%;
  }

  #content1
    .select_default_sty
    > .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue,
  #content2
    .select_default_sty
    > .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue,
  #content3
    .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue,
  #content4
    .select_default_sty
    > .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue,
  #content1-user
    .select_default_sty
    > .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue,
  #content2-user
    .select_default_sty
    > .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue,
  #content3-user
    .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue,
  #content4-user
    .select_default_sty
    > .css-yk16xz-control
    > .css-g1d714-ValueContainer
    > .css-1uccc91-singleValue {
    /* color: black; */
    text-shadow: 0 0 15px black;
    font-weight: bold;
    font-size: 1.3rem;
  }
}
