.kt-dialog.kt-dialog--top-center.kt-dialog--shown {
    top: 0px;
    /* top: 89px; */
    -webkit-transition: top 0.6s ease;
    transition: top 0.6s ease;
}

.kt-dialog.kt-dialog--loader {
    font-size: 1rem;
    padding: 0.75rem;
    font-weight: 400;
    color: #6c7293;
}
.kt-dialog.kt-dialog--top-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: -100px;
    left: 50%;
    margin-left: -65px;
    -webkit-transition: top 0.6s ease;
    transition: top 0.6s ease;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.kt-dialog {
    width: 130px;
    margin: 0 auto;
    position: fixed;
    z-index: 1100;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem;
    -webkit-box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 10%);
    background-color: #fff;
    border-radius: 4px;
}