.terms{
    background-image: url("../../assets/img/terms.png");
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size:cover;
     background-repeat: no-repeat;
     background-position: center center;
     /* padding: 180px 0; */
     padding: 150px 0 100px 0;
}

.terms h1{
    font-weight: 600;
    font-size: 76px;
    line-height: 80px;
    color: #FFFFFF;
}

.terms-enquries p{
    max-width:714px;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: rgba(226, 29, 0, 0.8);
}
@media screen and (max-width: 1024px) {
    .terms h1{
        font-size: 55px;
    }
  }