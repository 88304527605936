.modalTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
}

.modalPar {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #333333;
  max-width: 378px;
  margin: auto;
  margin-bottom: 10px;
}

.modContainer {
  padding: 10px 20px;
  /* margin-top: 20px; */

  overflow-y: auto;
  overflow-x: hidden;
  height: 480px;
}
.rodal-dialog {
min-height: 20px;
}
@media only screen and (max-width: 1024px) {
  .modContainer {
    padding: 0;
    margin: 10px 0;
    padding-bottom: 35px;
  }
  .modalTitle {
    font-style: 10px;
  }
}

.rodal-mask {
  background: rgba(51, 51, 51, 0.7) !important;
}

.rodal-dialog {
  border-radius: 15px !important;
  margin-top: 100px;
}

@media only screen and (max-width: 1024px) {
  .rodal-dialog {
    margin: 75px auto;
    width:95% !important;
  }
}
