.about {
  background: #e5e5e5;
  /* background-image:linear-gradient(248.08deg, rgba(90, 20, 9, 0.5) 13.9%, rgba(47, 81, 92, 0.5) 100.87%),
     url("../../assets/img/about.png"); */
  /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center; */
  padding-top: 50px;
  /* color: white; */
}

.about h1 {
  font-family: "EuclidCircularB-Bold";
  font-style: normal;
  font-size: 48px;
  line-height: 54px;
  max-width: 545px;
  /* color: rgba(255, 255, 255, 0.7); */
}
.about p {
  /* max-width:700px; */
  /* font-family: "EuclidCircularB-Bold"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  max-width: 427px;
  margin-bottom: 20px;
}

.clamp {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  transition: 0.3s ease-in-out;
}

.about-image-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.image-contain,
.news-contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-contain {
  flex-basis: 25%;
  align-items: center;
  margin-bottom: 20px;
}
.news-contain {
  flex-basis: 30%;
  min-height: 500px;
}

.news-para b {
  max-width: 380px;
  font-size: 15px;
  line-height: 24px;
}

.our-vision h2 {
  font-family: "EuclidCircularB-Bold";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.alittle p {
  font-size: 14px;
  line-height: 24px;
}

.alittle h1 {
  font-family: "EuclidCircularB-Bold";
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  position: relative;
}

.walletredline-about {
  position: absolute;
  left: 220px;
  top: 35px;
}

.about-img-holder {
  margin-top: -150px;
}

.mission-img {
  float: right;
  width: 60%;
  margin: 0 0px 20px 0;
}

.object-fit_contain {
  -o-object-fit: contain;
  object-fit: contain;
  height: 120px;
  width: 100%;
}

.mission-img2 {
  width: 30%;
  margin: 0px 0px 10px 0px;
  position: relative;
}

.rectangle {
  position: absolute;
  z-index: -1;
  top: -40px;
  left: 200px;
}
.ourteam {
  padding: 50px 0px 100px 0px;
}

.ourteam h1 {
  font-family: "EuclidCircularB-Bold";
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
}

.ourteam h2 {
  font-family: "EuclidCircularB-Bold";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.18px;
}

.ourteam p {
  width: 470px;
  font-size: 14px;
  line-height: 24px;
}

.team-icon {
  max-width: 50px;
  padding: 5px;
  background: #56ccf2;
  border: 1px solid #56ccf2;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;
}
.head-section {
  width: 100%;
  margin-top: 100px;
}
.head-section-text {
  width: 50%;
  overflow: hidden;
}
.head-section-image {
  width: 50%;
  height: 50%;
  padding-left: auto;
}
.the-team-section,
.our-leader-section {
  width: 100%;
  padding: 50px 0;
}

.the-team-section {
  background: #d4eef8;
}
.our-leader-section {
  background: #EAF5FA;;
}

@media only screen and (max-width: 1024px) {
  .head-section {
    flex-direction: column;
    margin-top: 30px;
  }
  .head-section-text {
    width: 100%;
    order: 2;
  }
  .head-section-image {
    order: 1;
    width: 100%;
    margin-bottom: 0 !important;
  }

  .the-team-section {
    padding-bottom: 10px;
  }

  .about p {
    margin-bottom: 20px;
  }

  .our-leader-section {
    padding: 20px 0;
  }

  .about-image-section {
    flex-direction: column;
    margin: 20px 0 !important;
    text-align: center!important;
  }

  .image-contain {
    margin-bottom: 20px;
  }
  .news-contain {
    align-items: center;
  }
  .about-img-holder {
    margin-top: 0px;
  }
  .mission-img {
    width: 100%;
  }
  .mission-img2 {
    width: 70%;
  }
  .ourteam p {
    width: 330px;
  }
  .rectangle {
    top: -15px;
    left: 190px;
  }
}
