.Button_Wrap {
  width: 100%;
  height: 45px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  background: red;
  /* background: #56CCF2; */
  border-radius: 8px;
  border: none;
  outline: none;
  transition: 1s ease;
}

.Button_Wrap:disabled {
  background: #696969 !important;
}
