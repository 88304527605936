.modal-success-img, .modal-failed-img, .modal-pending-img {
    border-radius: 50%;
    padding: 15px;
    background: #56cbf21c;
    width: 80px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.modal-failed-img {
    background: #e44b3d18;
}
.modal-pending-img {
    background: #ffbf4014;
}

.modal-success-label {
    font-size: 1.2rem;
    font-weight: bold;
}

.modal-success-card {
    background: #56cbf21c;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
}