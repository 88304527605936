.inputField_Wrap,
.bill-amount, .ant-select-selector {
  border: 1px solid rgba(242, 27, 27, 0.05);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  color: #333333;
  background: transparent;
  box-shadow: 0px 7px 5px rgba(242, 27, 27, 0.05);
  border-radius: 8px;
}
.inputField_Wrap:disabled,
.bill-amount:disabled {
  opacity: 0.5;
}

.inputField_Wrap:focus,
.bill-amount:focus {
  outline: none;
}
