.privacy-text{
    padding: 80px 0px 0px 0;
}

.privacy{
    padding: 140px 0px 80px 0;
    background: rgba(86, 204, 242, 0.2);
}

@media only screen and (max-width: 1024px) {
    
}

.privacy h1{
    font-family: "EuclidCircularB-Bold";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #1F1F1F;
}

.privacy p{
    font-size: 16px;
    line-height: 24px;
}

.cardholder-privacy{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.privacy-text p{
    max-width: 1239px;
    font-size: 15px;
    line-height: 24px;
}