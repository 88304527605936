.self-check {
    padding-top: 200px;
    height: auto;
    /* display: flex;
    flex-direction: column; */
    align-items: center;
}
@media only screen and (max-width: 650px) {
.self-check {
    padding-top: 120px;
}
}