.Referralholder{
    text-align: center;
    padding: 20px 0px;
    background: rgba(86, 204, 242, 0.05);
    margin: 40px 0px 0px 0px;
}

.Referralholder h3{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.referral-img{
    padding: 20px 0px;
}

.referral-code p{
    font-size: 16px;
    line-height: 21px;
}

.referral-code span{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.referral-text{
    max-width: 540px;
    font-size: 14px;
    line-height: 20px;
    margin: auto;
    padding: 10px 0px;
}

.referral-text span{
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
}
.referral-icon{
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.referralicon{
    background: #333333;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0px 10px 0px 0px;
}

.referralicon i{
   font-size: 18px;
   color: #fff;
}

.referral-terms p{
         font-size: 12px;
         line-height: 18px;
         color: #828282;
         padding: 10px 0px 0px 0px;
}

.referralbtn{
    width: 25%;
    margin: auto;
}


.referral-img1{
    border: solid;
    padding: 20px 0px;
}
@media only screen and (max-width:1024px){
    .referral-text{
        max-width: 85%;
    }
    .referralbtn{
        width: 50%;
    }
}