.reschedule{
    padding: 20px;
    background: rgba(86, 204, 242, 0.1);
    border-radius: 8px;
    margin: 40px 0px 0px 0px;
}

.reschedule-text{
    padding: 20px;
    border-radius: 14px;
    background: rgba(86, 204, 242, 0.1);
}

.reschedule-text p{
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
}

.reschedule-text h3{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.reschedule-text h2{
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
}
.reschedule-to{
    text-align: end;
}

.reschedulebtn{
    width: 60%;
    margin: auto;
}

@media only screen and (max-width:1024px){
    .reschedule-to{
        text-align: start;
    }
}